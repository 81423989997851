import {StrictMode} from 'react';
import {apollo} from '../graphql';
import {ApolloProvider} from '@apollo/client';
import ErrorBoundary from './ErrorBoundary';

/**
 * Common Shell for all root components
 */
export default function Shell({children}) {
    return <StrictMode>
        <ErrorBoundary fallback={error => <div className="alert alert-danger">{error}</div>}>
            <ApolloProvider client={apollo}>
                {children}
            </ApolloProvider>
        </ErrorBoundary>
    </StrictMode>;
}
