import {useNow} from '../contexts/CurrentTime';
import {applyTimezone, datetimeFormat} from '../utils';
import {useMemo} from 'react';

export default function RelativeTime({datetime}) {
    const now = useNow();

    const relativeSeconds = -1 * Math.trunc((new Date(datetime) - now) / 1000);
    const formatted = useMemo(() => datetimeFormat(applyTimezone(datetime)), [datetime]);

    if (Math.abs(relativeSeconds) < 3600) {
        const relativeTimeFormatter = new Intl.RelativeTimeFormat(window.config.language || 'de');
        if (Math.abs(relativeSeconds) < 60) {
            return <span title={formatted}>{relativeTimeFormatter.format(-1 * relativeSeconds, 'seconds')}</span>;
        } else {
            return <span title={formatted}>{relativeTimeFormatter.format(-1 * Math.trunc(relativeSeconds / 60), 'minutes')}</span>;
        }
    } else {
        return formatted;
    }
}
