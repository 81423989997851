import inlineLoadingSpinner from '/svg/inline_spinner.svg';

const previousIcons = new WeakMap();

export function disableSubmitButton(button) {
    // A button my have an icon on it already. If so, temporarily replace it
    const previousIcon = button.querySelector('i');
    if (previousIcon) {
        previousIcons.set(button, previousIcon);
        previousIcon.remove();
    }

    const loading = document.createElement('img');
    loading.src = inlineLoadingSpinner;
    loading.classList.add('inline-loading-spinner');

    button.disabled = true;
    button.prepend(loading);
}

export function enableSubmitButton(button) {
    button.querySelector('img')?.remove();
    button.disabled = false;

    if (previousIcons.has(button)) {
        button.prepend(
            previousIcons.get(button),
        );
        previousIcons.delete(button);
    }
}
