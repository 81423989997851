import React from 'react';
import {translate} from '../../../utils';

export default function StringFilter({internalOperators, setInternalOperators}) {
    function handleInput(event) {
        setInternalOperators({
            options: {
                ...internalOperators.options,
                [internalOperators.setting]: {
                    input: event.target.value,
                },
            },
            setting: internalOperators.setting,
        });
    }

    return <div className={'form-group'}>
            <input type="text" className="form-control" value={internalOperators.options[internalOperators.setting].input} onChange={handleInput} required autoFocus />
        </div>;
}


export const filterOptions = Object.freeze({
    type: 'string',
    transformFromApi: operators => {
        const usedOperators = Object.keys(operators).filter(op => ['like'].includes(op));
        if (!usedOperators.length) return null;

        return {
            setting: usedOperators[0],
            options: {
                [usedOperators[0]]: {
                    input: operators[usedOperators[0]],
                },
            },
        };
    },
    transformToApi: internalOperators => {
        return {
            [internalOperators.setting]: internalOperators.options[internalOperators.setting].input,
        };
    },
    displayValue: internalOperators => {
        return translate('frontend', 'FILTER_SELECTION_CONTAINS_TEXT', {text: internalOperators.options.like.input});
    },
    defaultFilter: () => {
        return {
            options: {
                like: {input: ''},
            },
            setting: 'like',
        };
    },
});
