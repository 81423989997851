import * as React from 'react';
import {translate} from '../utils';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {error: error.message};
    }

    render() {
        if (this.state.error) {
            const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
            return this.props.fallback(isDev ? this.state.error : translate('frontend', 'UNKNOWN_ERROR_OCCURRED'));
        }

        return this.props.children;
    }
}
