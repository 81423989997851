import React, {useState} from 'react';
import {translate} from '../../utils';
import {filtersMap} from './FiltersUtils';

export default function FilterBody({fieldSchema, internalOperators: initialInternalOperators, applyFilter}) {
    const [internalOperators, setInternalOperators] = useState(initialInternalOperators);

    if (!(fieldSchema.type in filtersMap)) {
        return <p className={'text-danger'}>{translate('frontend', 'FILTER_SELECTION_UNKNOWN_FILTER')}</p>;
    }

    const Content = filtersMap[fieldSchema.type].component;

    function handleSubmit(event) {
        applyFilter(internalOperators);
        event.preventDefault();
    }

    function applySuggestion(suggestionOperators) {
        applyFilter(
            // `suggestionOperators` may be partial. Copy it onto current input state, to allow updating other inputs if needed.
            Object.assign({}, internalOperators, suggestionOperators),
        );
    }

    return <form className="vstack gap-3 form-sm" onSubmit={handleSubmit}>
        <Content fieldSchema={fieldSchema} internalOperators={internalOperators} setInternalOperators={setInternalOperators} />
        <button type="submit" className="btn btn-sm btn-primary">{translate('frontend', 'FILTER_SELECTION_APPLY')}</button>
        <FilterSuggestions fieldSchema={fieldSchema} applySuggestion={applySuggestion}/>
    </form>;
}

function FilterSuggestions({fieldSchema, applySuggestion}) {
    const transformFromApi = filtersMap[fieldSchema.type].filterOptions.transformFromApi;
    const suggestions = (fieldSchema.suggestions ?? []).map(({label, operators}, idx) => {
        const applySelectedSuggestion = () => {
            applySuggestion(
                transformFromApi(operators, fieldSchema),
            );
        };
        return <button key={idx} type={'button'} className={'btn btn-default btn-sm'} onClick={applySelectedSuggestion}>{label}</button>;
    });

    if (!suggestions.length) return null;

    return <div className={'vstack gap-2'}>
        <hr data-content={translate('frontend', 'FILTER_SELECTION_SUGGESTION_TITLE')} className={'text-sm'}/>
        {suggestions}
    </div>;
}
