import FilterBody from './FilterBody';
import Popover from '../Popover';
import {translate} from '../../utils';
import {filtersMap} from './FiltersUtils';

export default function FilterChip({variant, label, fieldSchema, applyFilter, internalOperators}) {
    if (variant === 'suggested') {
        return <SuggestedFilterChip label={label} fieldSchema={fieldSchema} applyFilter={applyFilter} internalOperators={internalOperators}/>;
    } else if (variant === 'active') {
        return <ActiveFilterChip label={label} fieldSchema={fieldSchema} applyFilter={applyFilter} internalOperators={internalOperators}/>;
    }
}

function SuggestedFilterChip({label, fieldSchema, applyFilter, internalOperators}) {
    return <Popover title={translate('frontend', 'FILTER_SELECTION_BY_PARAM', {param: label})} content={<FilterBody fieldSchema={fieldSchema} applyFilter={applyFilter} internalOperators={internalOperators}/>}>
        <button type="button" className="btn btn-default">
            <i className="fa fa-plus-circle"></i> {label}
        </button>
    </Popover>;
}

function ActiveFilterChip({label, fieldSchema, applyFilter, internalOperators}) {
    function clearFilter() {
        applyFilter(null);
    }

    const displayValue = transformToDisplayValue(fieldSchema, internalOperators);

    return <div className="btn-group">
        <button type="button" className="btn btn-primary" onClick={clearFilter}>
            <i className="fa fa-times"></i>
        </button>
        <Popover title={translate('frontend', 'FILTER_SELECTION_BY_PARAM', {param: label})} content={<FilterBody fieldSchema={fieldSchema} applyFilter={applyFilter} internalOperators={internalOperators}/>}>
            <button type="button" className="btn btn-primary">{label}: {displayValue}</button>
        </Popover>
    </div>;
}

function transformToDisplayValue(fieldSchema, internalOperators) {
    const displayValue = fieldSchema.type in filtersMap ? filtersMap[fieldSchema.type].filterOptions.displayValue(internalOperators, fieldSchema) : undefined;

    return typeof displayValue === 'undefined' ? 'unknown' : displayValue;
}
