import React from 'react';
import InputCaret from './../InputCaret';
import {translate} from '../../../utils';

export default function DecimalFilter({internalOperators, setInternalOperators}) {
    let numeralFormat = window.config.basicSettings.default_numeral_format;
    let inputPatternRegex = numeralFormat === '.' ? '^\\d+([,]{0,1}\\d{3})*([.]{1}\\d{1,2}){0,1}$' : '^\\d+([.]{0,1}\\d{3})*([,]{1}\\d{1,2}){0,1}$';
    let inputPatternDescription = numeralFormat === '.' ? 'xxx,xxx.xx' : 'xxx.xxx,xx';

    function handleSettingChange(event) {
        setInternalOperators({
            ...internalOperators,
            setting: event.target.value,
        });
    }

    return <div className={'form-group'}>
        <select className="form-control" value={internalOperators.setting} onChange={handleSettingChange}>
            <option value="eq">{translate('frontend', 'FILTER_SELECTION_OPERATOR_IS_EQUAL_TO')}</option>
            <option value="between">{translate('frontend', 'FILTER_SELECTION_OPERATOR_IS_BETWEEN')}</option>
            <option value="gt">{translate('frontend', 'FILTER_SELECTION_OPERATOR_IS_GREATER_THAN')}</option>
            <option value="lt">{translate('frontend', 'FILTER_SELECTION_OPERATOR_IS_LESS_THAN')}</option>
        </select>
        <div className="hstack gap-1 mt-3">
            <div className={'hstack'}>
                <InputCaret/>
            </div>
            {internalOperators.setting === 'between'
                ? <DecimalFilterBetweenInput internalOperators={internalOperators} setInternalOperators={setInternalOperators} inputPatternRegex={inputPatternRegex} inputPatternDescription={inputPatternDescription}/>
                : <DecimalFilterSingleInput internalOperators={internalOperators} setInternalOperators={setInternalOperators} inputPatternRegex={inputPatternRegex} inputPatternDescription={inputPatternDescription}/>}
        </div>
    </div>;
}

function DecimalFilterSingleInput({internalOperators, setInternalOperators, inputPatternRegex, inputPatternDescription}) {
    function handleInput(event) {
        setInternalOperators({
            options: {
                ...internalOperators.options,
                [internalOperators.setting]: {
                    input: event.target.value,
                },
            },
            setting: internalOperators.setting,
        });
    }

    return <input type="text" pattern={inputPatternRegex} title={inputPatternDescription} className="form-control" value={internalOperators.options[internalOperators.setting].input} onChange={handleInput} required autoFocus/>;
}

function DecimalFilterBetweenInput({internalOperators, setInternalOperators, inputPatternRegex, inputPatternDescription}) {
    function handleFirstInput(event) {
        setInternalOperators({
            options: {
                ...internalOperators.options,
                between: [
                    {input: event.target.value},
                    internalOperators.options.between[1],
                ],
            },
            setting: internalOperators.setting,
        });
    }

    function handleSecondInput(event) {
        setInternalOperators({
            options: {
                ...internalOperators.options,
                between: [
                    internalOperators.options.between[0],
                    {input: event.target.value},
                ],
            },
            setting: internalOperators.setting,
        });
    }

    return <>
        <input type="text" pattern={inputPatternRegex} title={inputPatternDescription} className="form-control" value={internalOperators.options.between[0].input} onChange={handleFirstInput} required autoFocus/>
        {translate('frontend', 'FILTER_SELECTION_BETWEEN_SEPARATOR')}
        <input type="text" pattern={inputPatternRegex} title={inputPatternDescription} className="form-control" value={internalOperators.options.between[1].input} onChange={handleSecondInput} required/>
    </>;
}

export const filterOptions = Object.freeze({
    type: 'decimal',
    transformFromApi: operators => {
        const usedOperators = Object.keys(operators).filter(op => ['eq', 'gt', 'lt', 'ge', 'le'].includes(op));
        if (!usedOperators.length) return null;

        if (usedOperators.includes('ge') && usedOperators.includes('le')) {
            return {
                options: {
                    between: [
                        {
                            input: numberFormat(Number(operators.ge), 2, 1, window.config.basicSettings.default_numeral_format),
                        },
                        {
                            input: numberFormat(Number(operators.le), 2, 1, window.config.basicSettings.default_numeral_format),
                        },
                    ],
                },
                setting: 'between',
            };
        } else {
            return {
                options: {
                    [usedOperators[0]]: {
                        input: numberFormat(Number(operators[usedOperators[0]]), 2, 1, window.config.basicSettings.default_numeral_format),
                    },
                },
                setting: usedOperators[0],
            };
        }
    },
    transformToApi: internalOperators => {
        if (internalOperators.setting === 'between') {
            return {
                ge: numberFormat(internalOperators.options.between[0].input, 2, 2, window.config.basicSettings.default_numeral_format),
                le: numberFormat(internalOperators.options.between[1].input, 2, 2, window.config.basicSettings.default_numeral_format),
            };
        } else {
            return {
                [internalOperators.setting]: numberFormat(internalOperators.options[internalOperators.setting].input, 2, 2, window.config.basicSettings.default_numeral_format),
            };
        }
    },
    displayValue: internalOperators => {
        if (internalOperators.setting === 'between') {
            return translate('frontend', 'FILTER_SELECTION_BETWEEN_VALUE1_AND_VALUE2', {
                value1: internalOperators.options.between[0].input,
                value2: internalOperators.options.between[1].input,
            });
        } else if (internalOperators.setting === 'gt') {
            return translate('frontend', 'FILTER_SELECTION_GREATER_THAN_VALUE', {value: internalOperators.options.gt.input});
        } else if (internalOperators.setting === 'lt') {
            return translate('frontend', 'FILTER_SELECTION_LESS_THAN_VALUE', {value: internalOperators.options.lt.input});
        } else if (internalOperators.setting === 'eq') {
            return internalOperators.options.eq.input;
        }
    },
    defaultFilter: () => {
        return {
            options: {
                eq: {input: ''},
                between: [{input: ''}, {input: ''}],
                gt: {input: ''},
                lt: {input: ''},
            },
            setting: 'eq',
        };
    },
});
