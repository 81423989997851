import {useState} from 'react';
import FilterBody from './FilterBody';
import Popover from '../Popover';
import {translate} from '../../utils';

export default function AddFilters({applyFilter, schema, internalFilter, unusedFieldNames}) {
    const [selectedFieldName, setSelectedFieldName] = useState(null);

    const popoverTitle = selectedFieldName ? <>
        <button type={'button'} className={'btn btn-primary btn-sm mr-1'} onClick={() => setSelectedFieldName(null)}>
            <i className={'fa fa-chevron-left'}/></button> {translate('frontend', 'FILTER_SELECTION_BY_PARAM', {param: schema[selectedFieldName].label})}</> : null;
    let popoverContent;
    if (selectedFieldName !== null) {
        popoverContent =
            <FilterBody fieldSchema={schema[selectedFieldName]} internalOperators={internalFilter[selectedFieldName]} applyFilter={internalOperators => applyFilter(selectedFieldName, internalOperators)}/>;
    } else {
        popoverContent = unusedFieldNames.map(unusedFieldName => (
            <button key={unusedFieldName} type="button" className="btn btn-default btn-sm justify-start" onClick={() => setSelectedFieldName(unusedFieldName)}>
                <i className="fa fa-plus-circle text-muted"></i> {schema[unusedFieldName].label}
            </button>
        ));

        popoverContent = <div className={'vstack gap-1'}>{popoverContent}</div>;
    }

    return <Popover title={popoverTitle} content={popoverContent}>
        <button type="button" className="btn btn-default-clear">
            <i className="fa fa-filter"></i> {translate('frontend', 'FILTER_SELECTION_OTHER_FILTERS')}
        </button>
    </Popover>;
}
