import React, {useContext, useState} from 'react';
import useInterval from '../hooks/useInterval';

const CurrentTime = React.createContext(null);

/**
 * @typedef {"second"|"minute"} FidelityOption
 * @param {FidelityOption} fidelity What change should trigger a time change
 */
export function CurrentTimeProvider({children, fidelity}) {
    const [time, setTime] = useState(new Date());
    useInterval(() => {
        setTime((previousTime) => {
            const now = new Date();
            const sameSeconds = now.getSeconds() === previousTime.getSeconds();
            const sameMinutes = now.getMinutes() === previousTime.getMinutes();
            const sameHours = now.getHours() === previousTime.getHours();
            const sameDay = now.getDate() === previousTime.getDate();
            const sameMonth = now.getMonth() === previousTime.getMonth();
            const sameYear = now.getFullYear() === previousTime.getFullYear();

            if (fidelity === 'second') {
                if (!sameSeconds || !sameMinutes || !sameHours || !sameDay || !sameMonth || !sameYear) {
                    return now;
                }
            } else if (fidelity === 'minute') {
                if (!sameMinutes || !sameHours || !sameDay || !sameMonth || !sameYear) {
                    return now;
                }
            } else {
                console.error(`Unknown option: ${fidelity}`);
            }

            return previousTime;
        });
    }, 100);

    return <CurrentTime.Provider value={time}>{children}</CurrentTime.Provider>;
}

export function useNow() {
    // When there is no ancestor `CurrentTimeProvider` element, fallback to using render time
    return useContext(CurrentTime) || new Date();
}
