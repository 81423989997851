/**
 * Allows dynamically adding new form inputs from template code.
 *
 * Usage example:
 */
`
<div class="js-add-from-template">
    <template class="js-add-from-template__template">
        <div class="js-add-from-template__row" data-index="input_id_placeholder">
            <label for="books-input_id_placeholder">Book title</label>
            <input id="books-input_id_placeholder" name="books[input_id_placeholder]" type="text" value="" />
            <button type="button" class="js-add-from-template__row__remove-btn">Remove</button>
        </div>
    </template>

    <div class="js-add-from-template__container">
        <div class="js-add-from-template__row" data-index="0">
            <label for="books-0">Book title</label>
            <input id="books-0" name="books[0]" type="text" value="The Greatest Book Ever" />
            <button type="button" class="js-add-from-template__row__remove-btn">Remove</button>
        </div>
    </div>

    <button type="button" class="js-add-from-template__add-btn">Add</button>
</div>
`;

/**
 * If you don't add newly created input element to Yii ActiveForm client
 * attributes, then validation error messages won't work.
 *
 * @param $formElement
 * @param {string} placeholderInputId
 * @param {string} searchString
 * @param {string} replacementString
 */
function registerNewlyAddedInputAsYiiActiveField($formElement, placeholderInputId, searchString, replacementString) {

    if (!$formElement.length) {
        return;
    }

    // Find the attributes of our placeholder/template input element.
    //   These attributes represent a Yii ActiveField (but on JS side)
    //   and if they don't exist then Yii won't be able to
    //   display validation error message for the newly added input.
    const placeholderInputAttributes = $formElement.yiiActiveForm('find', placeholderInputId);

    // For consistency sake we just clone the existing placeholder input attributes,
    // because those attributes were added/created on the PHP side
    // and are more reliable than rolling our own attribute builder on JS side.
    // @see \frontend\addActiveFieldToClientAttributes
    var inputAttributes = Object.assign({}, placeholderInputAttributes);

    // Replace all placeholders with real IDs.
    Object.keys(inputAttributes).forEach(function (attributeName) {
        var isString = typeof inputAttributes[attributeName] === 'string';
        var containsPlaceholder = isString && inputAttributes[attributeName]
            .indexOf(searchString) > -1;
        if (!containsPlaceholder) return;

        inputAttributes[attributeName] = inputAttributes[attributeName]
            .replace(searchString, replacementString);
    });

    // Tells Yii that there is a new input field which represents an ActiveField input.
    // And from this point on validation errors should start working for our new input.
    $formElement.yiiActiveForm('add', inputAttributes);
}

/**
 * Initialize global event listeners
 */
function load() {
    const inputIdPlaceholderName = 'input_id_placeholder';
    const rootClassName = 'js-add-from-template';
    const templateClassName = `${rootClassName}__template`;
    const containerClassName = `${rootClassName}__container`;
    const addButtonClassName = `${rootClassName}__add-btn`;
    const rowClassName = `${rootClassName}__row`;
    const removeButtonClassName = `${rowClassName}__remove-btn`;

    function getNextInputNo(containerElement) {
        const rowElements = containerElement.querySelectorAll(`.${rowClassName}`);
        let maxId = -1;

        for (let rowElement of rowElements) {
            maxId = Math.max(maxId, rowElement.dataset.index);
        }

        return maxId + 1;
    }

    function removeInputFromActiveForm(rowElement) {
        const $formElement = window.$(rowElement.closest('form'));

        if (!$formElement.length) {
            return;
        }

        const inputElements = rowElement.querySelectorAll('input,select,textarea');

        // Remove inputs from Yii ActiveForm client attributes.
        for (let inputElement of inputElements) {
            $formElement.yiiActiveForm('remove', inputElement.id);
        }
    }

    function addInputRow(rootElement) {
        const templateElement = rootElement.querySelector(`.${templateClassName}`);
        const containerElement = rootElement.querySelector(`.${containerClassName}`);

        const inputNo = getNextInputNo(containerElement).toString();

        const templateDocRoot = document.importNode(templateElement.content, true);
        const rowElement = templateDocRoot.firstElementChild;

        if (!rowElement.matches(`.${rowClassName}`)) {
            console.error(`First template element should match .${rowClassName}`, {rowElement});
            alert('Failed adding new input, please check console');
            return;
        }

        const inputElements = rowElement.querySelectorAll('input,select,textarea,label');
        const elementIdsForYiiActiveForm = [];

        // Replace placeholder values to real IDs.
        for (let inputElement of inputElements) {
            if (inputElement instanceof HTMLLabelElement) {
                // <label> element has only "for" attribute
                // that we're interested in replacing with real id.
                inputElement.setAttribute(
                    'for',
                    inputElement.getAttribute('for').replace(inputIdPlaceholderName, inputNo)
                );
            } else {
                // Here we handle input elements that can contain id, name and value...

                // Save placeholder id for this specific input for later.
                const inputElementPlaceholderId = inputElement.id;
                elementIdsForYiiActiveForm.push(inputElementPlaceholderId);

                // Yii ActiveField container element also contains id in class.
                // If we don't replace it then yii won't be able to update error message.
                const inputContainerElement = inputElement.closest(`.field-${inputElementPlaceholderId}`);
                if (inputContainerElement) {
                    inputContainerElement.setAttribute('class',
                        inputContainerElement.getAttribute('class')
                            .replace(inputIdPlaceholderName, inputNo)
                    );
                }

                // Replace all placeholder attribute values to real id.
                for (let j = 0; j < inputElement.attributes.length; j++) {
                    const attribute = inputElement.attributes[j];
                    if (attribute.value.indexOf(inputIdPlaceholderName) === -1) {
                        continue;
                    }
                    inputElement.setAttribute(attribute.name, attribute.value.replace(inputIdPlaceholderName, inputNo));
                }
            }
        }

        rowElement.dataset.index = inputNo;

        // Add to DOM
        containerElement.appendChild(rowElement);

        // Add inputs to Yii ActiveForm, otherwise errors won't be displayed.
        elementIdsForYiiActiveForm.forEach(function (elementId) {
            const $formElement = window.$(rootElement.closest('form'));
            registerNewlyAddedInputAsYiiActiveField($formElement, elementId, inputIdPlaceholderName, inputNo);
        });
    }

    function removeInputRow(rowElement) {
        const rootElement = rowElement.closest(`.${rootClassName}`);
        const containerElement = rowElement.closest(`.${containerClassName}`);

        // Remove from Yii ActiveForm.
        removeInputFromActiveForm(rowElement);
        // Remove from DOM
        rowElement.parentNode.removeChild(rowElement);

        // Ensure at least 1 row always displayed.
        if (containerElement.querySelectorAll(`.${rowClassName}`).length === 0) {
            addInputRow(rootElement);
        }
    }

    // Handle click event for Add button.
    document.addEventListener('click', function (event) {
        const addButton = event.target.closest(`.${addButtonClassName}`);
        if (!addButton) return;

        const rootElement = addButton.closest(`.${rootClassName}`);
        addInputRow(rootElement);
    });

    // Handle click event for Remove button.
    document.addEventListener('click', function (event) {
        const removeBtn = event.target.closest(`.${removeButtonClassName}`);
        if (!removeBtn) return;

        const rowElement = removeBtn.closest(`.${rowClassName}`);
        removeInputRow(rowElement);
    });
}

export default {load, registerNewlyAddedInputAsYiiActiveField};
