export function isCellToday(start, end) {
    let dpToday = {
        midnight: DayPilot.Date.today(),
        noon: DayPilot.Date.today().addHours(12)
    };

    return (start === dpToday.midnight || start.getDatePart().addHours(12) === dpToday.noon)
        && (start.getDatePart().addHours(12) === end || start.getDatePart().addHours(24) === end);
}

export function isCellWeekend(dpDate) {
    return dpDate.dayOfWeek() === 6 || dpDate.dayOfWeek() === 0 ? true : false;
}
