import React from 'react';
import DateInput from '../../DateInput';
import InputCaret from './../InputCaret';
import {dateFormat, dateYMD, monthsList, translate} from '../../../utils';

export default function DateFilter({internalOperators, setInternalOperators}) {
    function handleSettingChange(event) {
        setInternalOperators({
            ...internalOperators,
            setting: event.target.value,
        });
    }

    let input;
    let showInputCaret = true;
    if (internalOperators.setting === 'month') {
        input = <DateFilterMonth internalOperators={internalOperators} setInternalOperators={setInternalOperators}/>;
    } else if (internalOperators.setting === 'eq') {
        input = <DateFilterEq internalOperators={internalOperators} setInternalOperators={setInternalOperators}/>;
    } else if (internalOperators.setting === 'between') {
        input = <DateFilterBetween internalOperators={internalOperators} setInternalOperators={setInternalOperators}/>;
        showInputCaret = false;
    }

    return <div className={'form-group'}>
        <select className="form-control" value={internalOperators.setting} onChange={handleSettingChange}>
            <option value="month">{translate('frontend', 'FILTER_SELECTION_OPERATOR_IS_ON_MONTH')}</option>
            <option value="between">{translate('frontend', 'FILTER_SELECTION_OPERATOR_IS_BETWEEN')}</option>
            <option value="eq">{translate('frontend', 'FILTER_SELECTION_OPERATOR_IS_EQUAL_TO')}</option>
        </select>
        <div className="hstack gap-1 mt-3">
            {showInputCaret && <InputCaret/>}
            {input}
        </div>
    </div>;
}

function DateFilterMonth({internalOperators, setInternalOperators}) {
    const monthNames = monthsList(window.config.language);

    function handleMonthInput(event) {
        setInternalOperators({
            options: {
                ...internalOperators.options,
                month: [
                    {input: event.target.value},
                    internalOperators.options.month[1],

                ],
            },
            setting: internalOperators.setting,
        });
    }

    function handleYearInput(event) {
        setInternalOperators({
            options: {
                ...internalOperators.options,
                month: [
                    internalOperators.options.month[0],
                    {input: event.target.value},

                ],
            },
            setting: internalOperators.setting,
        });
    }

    return <>
        <select name="month" className="form-control" value={internalOperators.options.month[0].input} onChange={handleMonthInput} autoFocus>
            {monthNames.map((month, idx) => <option key={idx + 1} value={idx + 1}>{month}</option>)}
        </select>
        <input type="number" className="form-control" max={9999} min={0} value={internalOperators.options.month[1].input} onChange={handleYearInput} required/>
    </>;
}

function DateFilterEq({internalOperators, setInternalOperators}) {
    function handleInput(event) {
        setInternalOperators({
            options: {
                ...internalOperators.options,
                eq: {
                    input: event.target.value,
                },
            },
            setting: internalOperators.setting,
        });
    }

    return <DateInput className={'form-control'} readOnly={false} value={internalOperators.options.eq.input} onChange={handleInput}/>;
}

function DateFilterBetween({internalOperators, setInternalOperators}) {
    function handleFirstInput(event) {
        setInternalOperators({
            options: {
                ...internalOperators.options,
                between: [
                    {input: event.target.value},
                    internalOperators.options.between[1],
                ],
            },
            setting: internalOperators.setting,
        });
    }

    function handleSecondInput(event) {
        setInternalOperators({
            options: {
                ...internalOperators.options,
                between: [
                    internalOperators.options.between[0],
                    {input: event.target.value},
                ],
            },
            setting: internalOperators.setting,
        });
    }

    return <div className={'vstack items-center'}>
        <DateInput className={'form-control'} readOnly={false} value={internalOperators.options.between[0].input} onChange={handleFirstInput}/>
        {translate('frontend', 'FILTER_SELECTION_BETWEEN_SEPARATOR')}
        <DateInput className={'form-control'} readOnly={false} value={internalOperators.options.between[1].input} onChange={handleSecondInput}/>
    </div>;
}

export const filterOptions = Object.freeze({
    type: 'date',
    transformFromApi: operators => {
        const usedOperators = Object.keys(operators).filter(op => ['eq', 'ge', 'le'].includes(op));
        if (!usedOperators.length) return null;

        if (usedOperators.includes('ge') && usedOperators.includes('le')) {
            const geDate = operators.ge.split('-');
            if (geDate[2] === '01' && dateYMD(new Date(geDate[0], geDate[1], 0)) === operators.le) {
                return {
                    options: {
                        month: [
                            {input: parseInt(geDate[1]).toString()},
                            {input: geDate[0]},
                        ],
                    },
                    setting: 'month',
                };
            } else {
                return {
                    options: {
                        between: [
                            {input: dateFormat(operators.ge)},
                            {input: dateFormat(operators.le)},
                        ],
                    },
                    setting: 'between',
                };
            }
        } else if (usedOperators.includes('eq')) {
            return {
                options: {
                    eq: {
                        input: dateFormat(operators.eq),
                    },
                },
                setting: 'eq',
            };
        }

        return null;
    },
    transformToApi: internalOperators => {
        if (internalOperators.setting === 'month') {
            const year = internalOperators.options.month[1].input.padStart(4, '0');
            const month = internalOperators.options.month[0].input.padStart(2, '0');
            const lastDayOfMonth = (new Date(year, month, 0)).getDate().toString().padStart(2, '0');
            return {
                ge: `${year}-${month}-01`,
                le: `${year}-${month}-${lastDayOfMonth}`,
            };
        } else if (internalOperators.setting === 'between') {
            return {
                ge: moment(internalOperators.options.between[0].input, window.config.basicSettings.setting_default_dateformat.toUpperCase(), true).format('YYYY-MM-DD'),
                le: moment(internalOperators.options.between[1].input, window.config.basicSettings.setting_default_dateformat.toUpperCase(), true).format('YYYY-MM-DD'),
            };
        } else if (internalOperators.setting === 'eq') {
            return {
                eq: moment(internalOperators.options.eq.input, window.config.basicSettings.setting_default_dateformat.toUpperCase(), true).format('YYYY-MM-DD'),
            };
        }
    },
    displayValue: internalOperators => {
        if (internalOperators.setting === 'month') {
            const year = internalOperators.options.month[1].input;
            const monthName = monthsList(window.config.language)[Number(internalOperators.options.month[0].input) - 1];
            return `${monthName} ${year}`;
        } else if (internalOperators.setting === 'between') {
            return internalOperators.options.between[0].input + ' - ' + internalOperators.options.between[1].input;
        } else if (internalOperators.setting === 'eq') {
            return internalOperators.options.eq.input;
        }
    },
    defaultFilter: () => {
        // By default use current date.
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear().toString().padStart(4, '0');
        const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const currentDay = currentDate.getDate().toString().padStart(2, '0');
        const lastDayOfMonth = (new Date(currentYear, currentMonth, 0)).getDate().toString().padStart(2, '0');

        return {
            options: {
                eq: {
                    input: dateFormat(`${currentYear}-${currentMonth}-${currentDay}`),
                },
                between: [
                    {
                        input: dateFormat(`${currentYear}-${currentMonth}-01`),
                    },
                    {
                        input: dateFormat(`${currentYear}-${currentMonth}-${lastDayOfMonth}`),
                    },
                ],
                month: [{input: Number(currentMonth).toString()}, {input: currentYear}],
            },
            setting: 'month',
        };
    },
});
