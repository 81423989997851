import FilterChip from './FilterChip';

export default function FilterChipList({applyFilter, schema, internalFilter, suggestedFieldNames, activeFieldNames}) {
    const chips = [];
    const inactiveSuggestedFieldNames = suggestedFieldNames.filter(field => !activeFieldNames.includes(field));

    for (const fieldName of activeFieldNames) {
        chips.push(
            <FilterChip key={fieldName}
                        variant={'active'}
                        label={schema[fieldName].label}
                        fieldSchema={schema[fieldName]}
                        applyFilter={(internalOperators) => applyFilter(fieldName, internalOperators)}
                        internalOperators={internalFilter[fieldName] ?? null}/>
        );
    }

    for (const fieldName of inactiveSuggestedFieldNames) {
        chips.push(
            <FilterChip key={fieldName}
                        variant={'suggested'}
                        label={schema[fieldName].label}
                        fieldSchema={schema[fieldName]}
                        applyFilter={(internalOperators) => applyFilter(fieldName, internalOperators)}
                        internalOperators={internalFilter[fieldName] ?? null}/>
        );
    }

    return chips;
}
