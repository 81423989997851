import {useEffect, useRef} from 'react';

export default function useEventListener(type, listener) {
    const callback = useRef();

    useEffect(() => {
        callback.current = listener;
    });

    useEffect(() => {
        const eventListener = (event) => {
            callback.current(event);
        };
        document.addEventListener(type, eventListener);
        return () => document.removeEventListener(type, eventListener);
    }, [type]);
}
