import $ from 'jquery';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {dateFormat} from '../utils';

export default function DateInput({removeButton = false, pluginOptions = null, readOnly = true, ...inputProps}) {
    const inputEl = useRef();
    const plugin = useRef();
    const onChange = useRef();

    useEffect(() => {
        onChange.current = inputProps.onChange;
    }, [inputProps.onChange]);

    const datepickerOptions = useMemo(() => {
        return {
            // https://bootstrap-datepicker.readthedocs.io/en/latest/options.html#format
            format: window.config.basicSettings.setting_default_dateformat,
            todayHighlight: true,
            weekStart: 1,
            autoclose: true,
            ...pluginOptions
        };
    }, [pluginOptions]);

    useEffect(() => {
        const pluginLocal = $(inputEl.current)
            .on('changeDate', (e) => {
                onChange.current(e);
            })
            .datepicker(datepickerOptions);
        plugin.current = pluginLocal;

        return () => {
            pluginLocal
                .off('changeDate')
                .datepicker('destroy');
        };
    }, [datepickerOptions]);

    const controlledValue = inputProps.value;
    useEffect(() => {
        const existingDate = plugin.current.datepicker('getDate');
        if (controlledValue) {
            const existingFormattedDate = existingDate ? dateFormat(existingDate) : null;
            if (controlledValue !== existingFormattedDate) {
                // To reduce amount of events triggerred, only sync dates when it is known to be different
                plugin.current.datepicker('setDate', controlledValue);
            }
        } else {
            if (existingDate) {
                // To reduce amount of events triggerred, only sync dates when it is known to be different
                plugin.current.datepicker('clearDates');
            }
        }
    }, [controlledValue]);

    const onAddonClick = useCallback(() => {
        plugin.current.datepicker('show');
    }, []);

    const onRemoveClick = useCallback(() => {
        plugin.current.datepicker('clearDates').trigger('change');
    }, []);

    return <div className="input-group">
        <input ref={inputEl} readOnly={readOnly} {...inputProps}/>
        {removeButton ?
            <span className="input-group-addon" onClick={onRemoveClick} style={{cursor: 'pointer'}}><i className="fa fa-times"/></span>
            : null}
        <span className="input-group-addon" onClick={onAddonClick} style={{cursor: 'pointer'}}><i className="fa fa-calendar"/></span>
    </div>;
}
