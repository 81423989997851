import {cloneElement, useEffect, useRef, useState} from 'react';
import useEventListener from '../hooks/useEventListener';
import {createPortal} from 'react-dom';

export default function Popover({title, children, content}) {
    const domNode = useRef();
    const [popoverBodyEl, setPopoverBodyEl] = useState(null);

    useEffect(() => {
        const $ref = $(domNode.current);
        let isCancelled = false;

        // popover plugin may not be loaded yet
        $(document).ready(function () {
            if (isCancelled) return;

            $ref.popover({
                template: '<div class="popover"><div class="arrow"></div><div class="popover-content" style="width: 260px"></div></div>',
                html: true,
                sanitize: false,
                placement: 'bottom',
                content: 'hi', // Dummy content that will get removed. Either `content` or `title` is required.
                animation: false, // Animations have to be off for `shown.bs.popover` to be triggered immediately
                trigger: 'manual',
                // container: 'body',
            });
        });


        return () => {
            isCancelled = true;
            if ($ref.popover) {
                $ref.popover('destroy');
            }
        };
    }, []);

    useEffect(() => {
        if (!domNode.current) return;

        function shownHandler() {
            const _popoverBodyEl = document
                .getElementById(domNode.current.getAttribute('aria-describedby'))
                ?.querySelector(':scope > .popover-content');
            if (_popoverBodyEl) {
                _popoverBodyEl.innerHTML = '';
                setPopoverBodyEl(_popoverBodyEl);
            }
        }

        function hideHandler() {
            setPopoverBodyEl(null);
        }

        const $ref = $(domNode.current);

        $ref
            .on('shown.bs.popover', shownHandler)
            .on('hide.bs.popover', hideHandler);

        return () => {
            $ref
                .off('shown.bs.popover', shownHandler)
                .off('hide.bs.popover', hideHandler);
        };
    }, []);

    useEventListener('click', (event) => {
        if (!popoverBodyEl) {
            // Popover isnt oppened, dont care.
            return;
        }
        if (!event.target.isConnected) {
            // Some content inside the popover likely changed upon a click. Assume this shouldnt cause the popover to close.
            return;
        }
        if (domNode.current.contains(event.target)) {
            // Clicking on the button is handled by parent component
            return;
        }

        const popoverContainer = popoverBodyEl.closest('.popover');
        if (!popoverContainer.contains(event.target)) {
            // Clicking outside of the popup should close it
            $(domNode.current).popover('hide');
        }
    });

    function togglePopup() {
        if (popoverBodyEl) {
            $(domNode.current).popover('hide');
        } else {
            $(domNode.current).popover('show');
        }
    }

    return <>
        {cloneElement(children, {ref: (ref) => (domNode.current = ref), onClick: togglePopup})}
        {popoverBodyEl
            ? createPortal(<PopoverBody title={title}>{content}</PopoverBody>, popoverBodyEl)
            : null}
    </>;
}

function PopoverBody({title, children}) {
    // bootstrap's popover doesnt allow changing title dynamically. Fake it by nesting another title/content so that we have control over it.
    return <div style={{margin: '-9px -14px'}}>
        {title ? <h3 className="popover-title text-bold">{title}</h3> : null}
        <div className="popover-content">{children}</div>
    </div>
}
