import $ from 'jquery';
import Bowser from "bowser";

/**
 * @returns {string[]}
 */
function getClosedTokens() {
    const closedTokens = [];
    const cookies = decodeURIComponent(document.cookie).split(';');
    for (let cookie of cookies) {
        cookie = cookie.trimStart();
        if (cookie.startsWith('admin-message-was-read')) {
            closedTokens.push(...cookie.substring('admin-message-was-read='.length).split(','));
            // No break. Same cookie may have been set multiple times with different paths. Gotta Read 'Em All
        }
    }

    return closedTokens;
}

/**
 * @param {string[]} tokens
 */
function saveClosedTokens(tokens) {
    document.cookie = `admin-message-was-read=${tokens.join(',')};path=/`;
}

/**
 * @param {string} token
 */
function markAsClosed(token) {
    const activeTokens = Array.from(document.querySelectorAll('.js-site-message'))
        .map(siteMessage => siteMessage.dataset.token);

    const closedTokens = getClosedTokens()
        // Cookie value is limited to 4096 bytes.
        // Must remove inactive tokens to prevent overflow.
        .filter((closedToken) => activeTokens.indexOf(closedToken) !== -1);
    closedTokens.push(token);
    saveClosedTokens(closedTokens);
}

export function showSiteMessages() {
    const closedTokens = getClosedTokens();

    for (const siteMessage of document.querySelectorAll('.js-site-message')) {
        if (closedTokens.indexOf(siteMessage.dataset.token) !== -1) {
            // Don't show closed messages
            continue;
        }

        const closeButton = siteMessage.querySelector('.js-close-admin-message');
        // In root admin, message preview doesn't have close button with class js-close-admin-message
        if (closeButton) {
            siteMessage.querySelector('.js-close-admin-message').addEventListener('click', () => {
                markAsClosed(siteMessage.dataset.token);
                $(siteMessage).hide(300);
            });
        }

        $(siteMessage).show();
    }
}

export function isSupportedBrowser() {
    const browser = Bowser.getParser(window.navigator.userAgent);

    const isSupported = browser.satisfies({
        chrome: ">=103",
        safari: ">=15.6",
        edge: ">=103"
    });

    return isSupported;
}
