import DateFilter, {filterOptions as dateFilterOptions} from './Filters/DateFilter';
import DecimalFilter, {filterOptions as decimalFilterOptions} from './Filters/DecimalFilter';
import EnumFilter, {filterOptions as enumFilterOptions} from './Filters/EnumFilter';
import StringFilter, {filterOptions as stringFilterOptions} from './Filters/StringFilter';

export const filtersMap = {
    [dateFilterOptions.type]: {
        component: DateFilter,
        filterOptions: dateFilterOptions,
    },
    [decimalFilterOptions.type]: {
        component: DecimalFilter,
        filterOptions: decimalFilterOptions,
    },
    [enumFilterOptions.type]: {
        component: EnumFilter,
        filterOptions: enumFilterOptions,
    },
    [stringFilterOptions.type]: {
        component: StringFilter,
        filterOptions: stringFilterOptions,
    },
};
